import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, UrlField, TextInput, DateInput, Button, TextField, EditButton, useListContext, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { InfluencerStatusSelectInput, InfluencerRatingBasedSelectInput, AdminUserSelectInput } from '../../UI/Input/SelectInput'

import { DateRangeInput } from '../../UI/Input/input';

const ListFilter = (props) => {
    const {

        setFilters,
        hideFilter
    } = useListContext();
    return (

        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <InfluencerStatusSelectInput label="Status" source="status" alwaysOn />
            <InfluencerRatingBasedSelectInput label="Rating" source="rating" alwaysOn />
            <AdminUserSelectInput label="Admin User" source="adminuser" alwaysOn />
            <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
                setFilters({ daterange: strdate })
            }} />
        </Filter>
    )
};

const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}


const InfluencerFullList = ({ ...props }) => {

    return (
        <List {...props} title="Influencer" filters={<ListFilter />} filter={{ uid: -1 }} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >
                <ProfileidFiled source="profile_id" label="Profile Id" />
                <TextField source='user.name' label="name" />
                <FunctionField source="video_url" label="Link" render={record => {
                    return (<Button target="_blank" style={{ color: 'green', border: '1px solid' }} href={record.video_url} label="Link" size="small"></Button>)

                }} />


                <TextField source="title" label="Title" />
                <TextField source="amount" label="Amount" />

                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Reject') {
                        return (<span style={{ color: 'red' }}>Reject</span>)
                    } else if (record['status'] === 'Pending') {
                        return (<span style={{ color: 'orange' }}>Pending</span>)
                    } else {
                        return (<span style={{ color: 'green' }}>Approved</span>)
                    }
                }} />
                <TextField source='submit_date' label="Date" />
                <FunctionField source="status" label="Edit Action" render={record => {
                    if (record['status'] === 'Pending') {
                        return (<EditButton />)
                    }

                }} />
                <ShowButton />

            </Datagrid>

        </List>
    )
}


export default InfluencerFullList